import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";

const TextBlock = styled.div`
  text-align: center;
`;

const ImageBlock = styled.div`
  text-align: center;
  margin-top: -20px;
  margin-bottom: 40px; 
`;

const NotFound = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div id="content" className="site-content">
      <div className="content-inner">
        <div className="">
          <div className="row content-row">
            <div id="primary" className="content-area col-12">
              <main id="main" className="site-main">
                <article
                  id="post-45"
                  className="post-45 page type-page status-publish hentry"
                >
                  <div className="entry-content clearfix">
                    <div
                      data-elementor-type="wp-page"
                      data-elementor-id="45"
                      className="elementor elementor-45"
                      data-elementor-settings="[]"
                    >
                      <div className="elementor-section-wrap">
                        <section
                          className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                          data-id="f6893e0"
                          data-element_type="section"
                          data-settings='{"stretch_section":"section-stretched"}'
                        >
                          <div className="elementor-container elementor-column-gap-default ">
                            <div
                              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                              data-id="9f5f77a"
                              data-element_type="column"
                            >
                              <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                  className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                  data-id="4522805"
                                  data-element_type="section"
                                >
                                  <div className="elementor-container elementor-column-gap-default ">
                                    <div
                                      className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                                      data-id="367b21f"
                                      data-element_type="column"
                                    >
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                          className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                          data-id="9265126"
                                          data-element_type="widget"
                                          data-widget_type="cms_image_single.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div className="cms-image-single cms-animation-left-to-right ">
                                              <div className="cms-img-box">
                                                <StaticImage
                                                  placeholder="none"
                                                  width="133"
                                                  height="137"
                                                  src="../images/image-home-2.png"
                                                  className="attachment-full size-full"
                                                  alt=""
                                                  loading="lazy"
                                                />{" "}
                                              </div>
                                            </div>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                                      data-id="8869f2a"
                                      data-element_type="column"
                                    >
                                      <div style={{ margin: '0 auto' }}>
                                        <div
                                          className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                          data-id="8832270"
                                          data-element_type="widget"
                                          data-widget_type="cms_heading.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">

                                              <div className="custom-subheading">
                                                Page not found
                                              </div>

                                              <h3 className="custom-heading">
                                                <span>404</span>
                                              </h3>
                                            </div>{" "}
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-6f927b2 elementor-widget elementor-widget-cms_image_single mb-3"
                                            data-id="6f927b2"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single animation-none wow flash">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="99"
                                                    height="23"
                                                    src="../images/image-bee.png"
                                                    className="attachment-full size-full"
                                                    alt="Kindori"
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>


                                          <TextBlock>Uh Oh, we can't seem to find the page you're looking for. Try going back to the previous page.</TextBlock>

                                          <div>

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6be929f"
                                      data-id="6be929f"
                                      data-element_type="column"
                                    >
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                          className="elementor-element elementor-element-97fbc33 cms-animation-bottom-to-top2 elementor-widget elementor-widget-cms_image_single"
                                          data-id="97fbc33"
                                          data-element_type="widget"
                                          data-widget_type="cms_image_single.default"
                                        >
                                          <div className="elementor-widget-container">
                                            <div className="cms-image-single cms-animation-bottom-to-top ">
                                              <div className="cms-img-box">
                                                <StaticImage
                                                  placeholder="none"
                                                  width="180"
                                                  height="186"
                                                  src="../images/image-home-3.png"
                                                  className="attachment-full size-full"
                                                  alt=""
                                                  loading="lazy"
                                                />{" "}
                                              </div>
                                            </div>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-section elementor-inner-section elementor-element elementor-element-b410ac4 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                  data-id="b410ac4"
                                  data-element_type="section"
                                >
                                  <div className="elementor-container elementor-column-gap-default ">
                                    <div
                                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-0b4fe1f"
                                      data-id="0b4fe1f"
                                      data-element_type="column"
                                    >
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                          className="elementor-element elementor-element-1bae692 cms-animation-bottom-to-top elementor-widget elementor-widget-cms_image_single"
                                          data-id="1bae692"
                                          data-element_type="widget"
                                          data-widget_type="cms_image_single.default"
                                        >
                                          <div className="elementor-widget-container mb-3">
                                            <div className="cms-image-single cms-animation-bottom-to-top2 ">
                                              <div className="cms-img-box">
                                                <StaticImage
                                                  placeholder="none"
                                                  width="132"
                                                  height="116"
                                                  src="../images/image-home-1.png"
                                                  className="attachment-full size-full"
                                                  alt=""
                                                  loading="lazy"
                                                />{" "}
                                              </div>
                                            </div>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </article>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFound;
